.leads-envia-table-row {
    border-bottom: 1px solid var(--rs-border-primary);
}

.leads-envia-table-row:hover .rs-table-row-expanded {
    background-color: var(--rs-state-hover-bg);
}

.leads-envia-cell-cnpj {
    display: flex;
    gap: 5px;
}

.leads-envia-cell-cnpj button {
    padding: 0px !important;
    width: 20px !important;
    height: 20px !important;
}

.leads-envia-cell-cnpj button svg {
    font-size: 10px !important;
}

.leads-envia-cell-cnae-data {
    margin-left: 40px;
    width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.leads-envia-custom-header {
    margin-bottom: 10px !important;
    padding: 0 !important;
}

.leads-envia-custom-header .rs-panel-body {
    padding: 10px !important;
    padding-bottom: 5px !important;
}

.leads-envia-custom-header .flex-box-grid-input {
    margin-bottom: 10px;
}

.leads-envia-custom-header .stack-footer {
    padding: 5px !important;
    margin-bottom: 5px !important;
}

.leads-envia-custom-header .stack-footer .checkbox-group {
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    padding-left: 10px;
    margin-right: 10px;
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.leads-envia-custom-header .stack-footer .checkbox-group label {
    margin-right: 10px;
}

.leads-envia-custom-header .stack-header {
    padding: 10px 5px 15px 5px !important;
    font-size: 16px !important;
    line-height: 2 !important;
}

.leads-envia-custom-header .total-selected {
    font-size: 16px !important;
    font-weight: bold;
    border: 1px solid var(--rs-gray-800);
    background-color: var(--rs-gray-600);
    color: var(--rs-gray-0);
    border-radius: 6px;
    padding: 5px 10px;
}

.leads-envia-custom-header .dateInput {
    width: 100%;
}

.leads-envia-custom-header .currencyInput {
    border: none;
    outline: none;
    width: 100%;
    padding: 7px 11px;
    background-color: var(--rs-input-bg);
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
