.panel-security-api {
    padding-bottom: 15px !important;
}

.panel-security-api .rs-panel-header {
    margin: 10px !important;
    padding: 10px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.divider-security-api {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    padding-top: 30px !important;
    width: 100% !important;
}
