.estatistica-cnae-custom-header {
    margin-bottom: 10px !important;
    padding: 0 !important;
}

.estatistica-cnae-custom-header .rs-panel-body {
    padding: 10px !important;
    padding-bottom: 5px !important;
}

.estatistica-cnae-custom-header .rs-flex-box-grid-item {
    margin-bottom: 10px;
}

.estatistica-cnae-custom-header .stack-footer-container {
    display: flex;
    align-items: flex-end;
}

.estatistica-cnae-custom-header .stack-footer {
    margin-top: 5px !important;
}

.estatistica-cnae-custom-header .radio-group {
    width: 100%;
    justify-content: space-around;
}

.estatistica-cnae-custom-header .cnae-sem-cliente {
    height: 60px;
    display: flex;
    align-items: flex-end;
}

.estatistica-cnae-table .rs-table-row-header .rs-table-cell-content {
    white-space: normal !important;
    flex-wrap: nowrap !important;
    text-align: center !important;
}

.estatistica-cnae-table .rs-table-row .rs-table-cell-content {
    padding: 9px 10px !important;
}

.estatistica-cnae-table .rs-table-row .rs-table-cell-content button {
    padding: 4px !important;
}

.estatistica-cnae-table .rs-table-row-header .rs-table-cell-content .header-clientes {
    color: var(--rs-blue-600);
}
.estatistica-cnae-table .rs-table-row-header .rs-table-cell-content .header-empresas {
    color: var(--rs-orange-800);
}
.estatistica-cnae-table .rs-table-row-header .rs-table-cell-content .header-abertas {
    color: var(--rs-color-green);
}
.estatistica-cnae-table .rs-table-row-header .rs-table-cell-content .header-encerradas {
    color: var(--rs-color-red);
}

.estatistica-cnae-custom-cell-number {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 15px;
}

.estatistica-cnae-custom-cell-number.positivo {
    color: var(--rs-blue-600);
}
.estatistica-cnae-custom-cell-number.negativo {
    color: var(--rs-color-red);
}

.estatistica-cnae-custom-cell-number.clientes {
    color: var(--rs-blue-600);
}
.estatistica-cnae-custom-cell-number.empresas {
    color: var(--rs-orange-800);
}
.estatistica-cnae-custom-cell-number.abertas {
    color: var(--rs-color-green);
}
.estatistica-cnae-custom-cell-number.encerradas {
    color: var(--rs-color-red);
}
