.consulta-leads .emphasis {
    font-weight: bold;
    font-size: 1.1em;
    color: yellow;
    padding: 0 5px;
}

.consulta-leads .currencyInput {
    border: none;
    outline: none;
    width: 100%;
    padding: 7px 11px;
    background-color: var(--rs-input-bg);
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

.consulta-leads .radio-group {
    width: 100%;
    justify-content: space-around;
}

.consulta-leads .tag-count {
    font-size: 15px !important;
    padding: 7px 15px !important;
}

.consulta-leads .message-download {
    font-size: 15px !important;
    padding: 10px 15px;
    width: 100%;
    background-color: #eee;
    border: 1px solid #ddd;
}

.rs-theme-dark .consulta-leads .message-download {
    background-color: transparent;
    border-color: #333;
    color: #999;
}

.consulta-leads .footer-data-panel {
    margin-top: 10px;
    padding: 0 5px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.consulta-leads .footer-data-panel .rs-stack {
    height: 100% !important;
    width: 100% !important;
}
.consulta-leads .rs-divider-horizontal {
    margin: 10px 0;
}

.consulta-leads .rs-loader-content {
    font-size: 16px !important;
    line-height: 1 !important;
    color: var(--rs-orange-500);
}
.consulta-leads .download-button {
    min-width: 125px;
    height: 36px !important;
}
.consulta-leads .download-button .rs-loader {
    margin-top: 5px !important;
}
.consulta-leads .download-button .rs-loader-content {
    font-size: 16px !important;
    line-height: 1 !important;
    color: #333 !important;
}
.consulta-leads-radio label {
    white-space: break-spaces !important;
    text-align: left !important;
}
.consulta-leads-label-desconsiderar-clientes {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    white-space: nowrap;
}

.consulta-leads .flex-grid-form > .rs-flex-box-grid-item {
    margin-bottom: 10px !important;
}
.consulta-leads .flex-grid-form > .rs-flex-box-grid-item:last-child {
    margin-bottom: 0px !important;
}

.consulta-leads-busca-termo-container {
    display: flex;
    gap: 10px;
}

.consulta-leads-busca-termo {
    width: 100%;
}

.consulta-leads-busca-termo-tipo {
    min-width: 325px;
}

.consulta-leads #desconsiderarCliente {
    padding-left: 25px !important;
}
