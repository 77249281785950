.cnpj-consultar .field-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    gap: 4px;
}

.cnpj-consultar .field-value {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    border: 1px solid var(--rs-border-primary);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
}

.cnpj-consultar .socios-table-row {
    border-bottom: 1px solid var(--rs-border-primary);
}

.cnpj-consultar .socios-table-row:hover .rs-table-row-expanded {
    background-color: var(--rs-state-hover-bg);
}

.cnpj-consultar .socios-cell-data {
    margin-left: 40px;
    width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cnpj-consultar-cnpj-mesmo-grupo-inativa {
    color: var(--rs-red-400);
}

.cnpj-consultar .rs-tabs-content {
    min-height: 560px;
}

.cnpj-consultar .loading-full-screen {
    height: 742px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cnpj-consultar-modal-empresa-socio {
    width: 80% !important;
}
.cnpj-consultar-modal-empresa-socio .rs-modal-body {
    min-height: 400px;
}

@media (width <= 1300px) {
    .cnpj-consultar-modal-empresa-socio {
        width: 100% !important;
    }
}
