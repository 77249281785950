.containerPickerValue {
    white-space: pre-line !important;
}

.containerPickerValue .rs-tag {
    margin-right: 10px !important;
    margin-left: 0px !important;
}

.combo-load-more {
    margin-top: 10px;
    margin-bottom: 5px;
}
