.container-login {
    height: 100vh;
    background-color: #eee; /* For browsers that do not support gradients */
    background-image: linear-gradient(#bbb, #eee);
    display: flex;
    align-items: center;
    padding-top: 120px;
}

.container-login .data-panel {
    padding: 15px;
    max-width: 450px;
}

.container-logo-login {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 75px;
}

.container-logo-login img {
    width: 100%;
}

.footer-login {
    padding: 20px 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.footer-login a {
    text-decoration: none !important;
}
