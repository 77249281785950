.data-modal-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    margin-bottom: 20px !important;
    padding-bottom: 0px !important;
}

.data-modal-button-group {
    padding-right: 5px;
    padding-left: 5px;
    gap: 10px;
}
