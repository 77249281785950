.select-empresa-load-more {
    color: var(--rs-text-primary);
    background-color: var(--rs-bg-overlay);
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 4px 0;
    font-size: 14px;
    font-weight: bold;
}

.select-empresa-searching {
    color: var(--rs-text-primary);
    background-color: var(--rs-bg-overlay);
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 4px 0;
    font-size: 14px;
    font-weight: bold;
}
