.sidebar-menu-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 3;
  height: 100vh;
  background-color: var(--rs-gray-50);
}

.rs-theme-high-contrast .sidebar-menu-container,
.rs-theme-dark .sidebar-menu-container {
  background-color: var(--rs-gray-800);
}

.brand-container {
  -webkit-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  width: 56px;
  height: 93px;
  border-bottom: solid 1px #d5d5d5;
}
.brand-container.expanded {
  width: 100%;
  overflow: hidden;
}

.brand-logo {
  height: 25px;
}
.brand-container.expanded .brand-logo {
  height: 65px;
}

.menu-container {
  height: 100%;
}

.menu-body {
  /* altura total - altura do logo - altura do rodape suporte */
  height: calc(100vh - 95px - 57px) !important;
  overflow: auto;
}
.menu-body a.rs-sidenav-item {
  transition: color 0s !important;
}
.menu-body a.rs-sidenav-item svg {
  top: 17px !important;
}
.menu-body::-webkit-scrollbar {
  width: 0.5em;
}
.menu-body::-webkit-scrollbar-track {
  background: var(--rs-gray-400);
}

.menu-avatar-container {
  border-bottom: solid 1px #d5d5d5;
  text-align: center;
}
.menu-avatar-username {
  font-size: 1.5em;
  font-weight: bold;
  padding-top: 2px;
}
.menu-avatar-office {
  padding-top: 2px;
  font-size: 1.1em;
}
.menu-avatar-company {
  padding-top: 12px;
  font-size: 0.88em;
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  animation: scrollText 5s linear infinite;
}

.link-menu-item a,
.link-menu-item a:hover,
.link-menu-item a:visited,
.link-menu-item a:focus {
  text-decoration: none;
  color: var(--rs-sidenav-default-hover-text);
}

.nav-toggle {
  -webkit-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  width: 56px;
  border-top: solid 1px #d5d5d5;
}
.nav-toggle.expanded {
  width: 100%;
}

.nav-item-suporte {
  width: calc(100% - 56px);
}
.nav-item-suporte .rs-navbar-item {
  font-size: 16px;
  line-height: 1.25;
  white-space: normal;
  width: 100%;
}

.page-container {
  min-height: 100vh;
  margin-left: 260px;
  transition: margin 0.5s;
  background-color: var(--rs-gray-200);
}
.page-container.expanded {
  margin-left: 56px;
}
.rs-theme-dark .page-container {
  background-color: transparent;
}

.page-content {
  padding: 0 20px;
}

.header-container {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 499;
  padding: 5px;
}
.header-container .menu-usuario {
  cursor: pointer;
}
.header-container .saldo-creditos-container {
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  background-color: #4fbb5e;
  /* border: solid 2px #187a18; */
  height: 40px;
  padding: 0 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: default;
}
.header-container .empresa-ativa-container {
  background-color: #3498ff;
  height: 40px;
  padding: 3px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-menu-container {
  width: 230px !important;
}

.user-menu-item {
  display: flex !important;
  align-items: center;
  gap: 10px;
}
.user-menu-item * {
  margin: 0 !important;
}
.user-menu-item.panel {
  padding: 8px 12px;
}
