.datatable-title {
    margin: 25px 0;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
}

.datatable-subtitle {
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}
