* {
  margin: 0;
  padding: 0;
  font-family: "Lato";
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--rs-gray-600);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--rs-gray-800);
}

.data-panel {
  background-color: #fff;
}
.rs-theme-dark .data-panel {
  background-color: transparent;
}

.rs-btn.rs-btn-ghost:hover {
  transform: scale(1.05);
  color: #fff;
}
.rs-btn.rs-btn-ghost:active {
  transform: scale(0.95);
}
.rs-btn.rs-btn-ghost.rs-btn-red:hover {
  background-color: var(--rs-red-400);
}
.rs-btn.rs-btn-ghost.rs-btn-red:active {
  background-color: var(--rs-red-500);
}
.rs-btn.rs-btn-ghost.rs-btn-green:hover {
  background-color: var(--rs-green-400);
}
.rs-btn.rs-btn-ghost.rs-btn-green:active {
  background-color: var(--rs-green-500);
}
.rs-btn.rs-btn-ghost.rs-btn-blue:hover {
  background-color: var(--rs-blue-400);
}
.rs-btn.rs-btn-ghost.rs-btn-blue:active {
  background-color: var(--rs-blue-500);
}
.rs-btn.rs-btn-ghost.rs-btn-orange:hover {
  background-color: var(--rs-orange-400);
}
.rs-btn.rs-btn-ghost.rs-btn-orange:active {
  background-color: var(--rs-orange-500);
}
.rs-btn.rs-btn-ghost.rs-btn-yellow:hover {
  background-color: var(--rs-yellow-400);
}
.rs-btn.rs-btn-ghost.rs-btn-yellow:active {
  background-color: var(--rs-yellow-500);
}
.rs-btn.rs-btn-ghost.rs-btn-cyan:hover {
  background-color: var(--rs-cyan-400);
}
.rs-btn.rs-btn-ghost.rs-btn-cyan:active {
  background-color: var(--rs-cyan-500);
}
.rs-btn.rs-btn-ghost.rs-btn-violet:hover {
  background-color: var(--rs-violet-400);
}
.rs-btn.rs-btn-ghost.rs-btn-violet:active {
  background-color: var(--rs-violet-500);
}

.avatar-perfil-200 {
  height: 200px !important;
  width: 200px !important;
  border: solid 1px #ccc;
}
.avatar-perfil-300 {
  height: 300px !important;
  width: 300px !important;
  border: solid 1px #ccc;
}
.avatar-perfil-200 img,
.avatar-perfil-300 img {
  height: fit-content !important;
  width: 100% !important;
}

.flex-grid-form > .rs-flex-box-grid-item {
  margin-bottom: 18px !important;
}

.flex-grid-form > .rs-flex-box-grid-item:last-child,
.flex-grid-form > .rs-flex-box-grid-item.no-margin-bottom {
  margin-bottom: 0px !important;
}

.bg-gradient-orange {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%);
}
.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}
.bg-gradient-green {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}
.bg-gradient-blue {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.bold {
  font-weight: bold !important;
}
.red {
  color: red !important;
}

.header-help {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.3;
  border: 1px solid;
  border-radius: 15px;
  cursor: default;
  width: 18px;
  height: 18px;
  margin-left: 4px;
  text-align: center;
}
