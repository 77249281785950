.download-page .progress {
    padding: 0;
    padding-left: 7px;
}
.download-page .update-info {
    margin-bottom: 10px;
    margin-top: 5px;
}
.download-page .panel-container {
    padding: 0;
}
.download-page .panel-container .rs-panel-header {
    padding: 15px;
}
.download-page .panel-container .rs-panel-body {
    padding: 15px;
    padding-top: 0;
}
.download-page .panel-info .rs-panel-body {
    font-size: 17px;
    padding: 15px;
    color: #fff;
    align-items: center;
    gap: 20px;
    display: flex;
}
.download-page .panel-info .value {
    font-weight: 700;
}

.download-page .container-buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 81px;
}

.download-page .container-buttons .rs-btn {
    min-width: 260px !important;
}

.download-page .rs-panel {
    background-color: #fff;
    margin-bottom: 0;
}
.rs-theme-dark .rs-panel {
    background-color: transparent;
}

.download-page .row-color .rs-table-cell {
    background: #d7d7d7;
}
.download-page .row-color .rs-progress-line-inner {
    background: #fff;
}

.rs-theme-dark .download-page .row-color .rs-table-cell {
    background: #090909;
}
.rs-theme-dark .download-page .row-color .rs-progress-line-inner {
    background: var(--rs-progress-bg);
}

.download-page .column-values {
    font-size: 14px;
    width: 270px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
    gap: 10px;
}
