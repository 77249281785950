.vertical-title-container {
    display: flex;
}

.vertical-title-text-container {
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.vertical-title-text-container {
    background-color: #efefef;
    color: #777;
}

.rs-theme-dark .vertical-title-text-container {
    background-color: #292d33;
    color: #fff;
}

.vertical-title-text {
    font-size: 18px;
    font-weight: 600;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    cursor: default;
}

.vertical-title-content {
    flex: 1;
}
